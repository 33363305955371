<template>
  <div>
    <!-- <TopCarousel /> -->
    <img class="w-full banner-top" src="@/assets/images/aboutUs/exhibitionDetail/banner.png" />
    <ContentArea>
      <div class="title">
        {{ detailObj.title }}
      </div>
      <div class="desc">
        <a-row :gutter="20">
          <a-col :lg="8" :xs="24" v-for="(item,idx) of params" :key="idx">
            <span>{{ item.name }}</span>：
            <span>{{ item.value }}</span>
          </a-col>
        </a-row>
      </div>
      <div v-html="htmlStr" class="content"></div>
    </ContentArea>
  </div>
</template>
<script>

export default {
  name: 'ExhibitionDetail',
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      detailObj: {}
    }
  },
  computed: {
    params () {
      return JSON.parse(this.detailObj.parameter || '[]')
    },
    htmlStr () {
      const str = this.detailObj.detail || ''
      return str.replace(new RegExp('<p>(?=<img.*)', 'g'), "<p class='img-container'>").replaceAll('/dev-api', this.fileBaseUrl)
    }
  },
  created () {
    this.$aboutUs.exhibitionInfo(this.$route.query.id).then(res => {
      this.detailObj = res.data || {}
    })
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.content-width {
  width: 80%;
}

.title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.desc {
  margin: 24px 0;
  padding: 16px;
  background: #E8E8E8;

  .ant-col {
    font-size: 12px;
  }
}

.content {

  & /deep/ .img-container {
    margin: 22px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 400px;
    }
  }
}
</style>
